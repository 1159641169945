<template>
    <div class="login__screen row">
        <div class="col-3 login__screen-left">
            <div class="padding-left">
                <img src="https://bauer.a.bigcontent.io/v1/static/logo-bauer-wht" alt="Bauer Logo" />
                <h2>Business</h2>
                <h2>Intelligence</h2>
            </div>
        </div>
        <div class="col-9 login__screen-right">
            <div class="login">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Auth',
};
</script>
